import * as yup from "yup";

const customizationSchema = (props) => {
  const SUPPORTED_TARGET_FORMATS = ["image/jpg", "image/jpeg"];
  const SUPPORTED_VIDEO_FORMATS = ["video/mp4"];
  const SUPPORTED_ASSET_FORMATS = [
    "zip,application/octet-stream",
    "application/zip",
    "application/x-zip",
    "application/x-zip-compressed",
  ];
  const IMAGE_SIZE = 4000 * 1024; // 4MB
  const VIDEO_SIZE = 20000 * 1024; // 20MB
  const ASSET_SIZE = 50000 * 1024; // 50MB

  const schema = yup.object().shape({
    contentStyle: yup.string().nullable().required(),
    freemium: yup.string().nullable().required(),
    thumbnail: yup
      .mixed()
      .test(
        "fileFormat",
        "select a valid file: .jpg or .jpeg",
        function (value) {
          if (value.length > 0) {
            return SUPPORTED_TARGET_FORMATS.includes(value[0].type);
          } else {
            return true;
          }
        }
      )
      .test("fileSize", "File too large", function (value) {
        if (value.length > 0) {
          return value[0].size <= IMAGE_SIZE;
        } else {
          return true;
        }
      }),
    gif: yup
      .mixed()
      .test(
        "fileFormat",
        "select a valid file: .jpg or .mp4",
        function (value) {
          if (value.length > 0) {
            return SUPPORTED_VIDEO_FORMATS.includes(value[0].type);
          } else {
            return true;
          }
        }
      )
      .test("fileSize", "File too large", function (value) {
        if (value.length > 0) {
          return value[0].size <= VIDEO_SIZE;
        } else {
          return true;
        }
      }),
    marker: yup
      .mixed()
      .test(
        "fileFormat",
        "select a valid file: .jpg or .jpeg",
        function (value) {
          if (value.length > 0) {
            return SUPPORTED_TARGET_FORMATS.includes(value[0].type);
          } else {
            return true;
          }
        }
      )
      .test("fileSize", "File too large", function (value) {
        if (value.length > 0) {
          return value[0].size <= IMAGE_SIZE;
        } else {
          return true;
        }
      }),
    video: yup
      .mixed()
      .test("fileFormat", "select a valid video file: .mp4", function (value) {
        if (value.length > 0) {
          return SUPPORTED_VIDEO_FORMATS.includes(value[0].type);
        } else {
          return true;
        }
      })
      .test("fileSize", "File too large", function (value) {
        if (value.length > 0) {
          return value[0].size <= VIDEO_SIZE;
        } else {
          return true;
        }
      }),
    bundleAppStore: yup
      .mixed()
      .test(
        "fileFormat",
        "select a valid file: .zip with .assetbundle inside it",
        function (value) {
          if (value.length > 0) {
            return SUPPORTED_ASSET_FORMATS.includes(value[0].type);
          } else {
            return true;
          }
        }
      )
      .test("fileSize", "AssetBundle too large", function (value) {
        if (value.length > 0) {
          return value[0].size <= ASSET_SIZE;
        } else {
          return true;
        }
      }),
    bundlePlayStore: yup
      .mixed()
      .test(
        "fileFormat",
        "select a valid file: .zip with .assetbundle inside it",
        function (value) {
          if (value.length > 0) {
            return SUPPORTED_ASSET_FORMATS.includes(value[0].type);
          } else {
            return true;
          }
        }
      )
      .test("fileSize", "AssetBundle too large", function (value) {
        if (value.length > 0) {
          return value[0].size <= ASSET_SIZE;
        } else {
          return true;
        }
      }),
  });
  return schema;
};

export { customizationSchema };
