import React, { Suspense } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ImageIcon from "@material-ui/icons/Image";
import MovieIcon from "@material-ui/icons/Movie";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { customizationSchema } from "../components/schema/customization";
import { useTranslation } from "react-i18next";
import ReactHookFormRadio from "../../../components/common/form/ReactHookFormRadio";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  input: {
    display: "none",
  },
}));

export default function Customization(props) {
  const classes = useStyles();
  const { t } = useTranslation("translation");
  const { onClose } = props;

  const { register, handleSubmit, errors, control } = useForm({
    resolver: yupResolver(customizationSchema(props)),
  });

  const handleBack = () => {
    onsubmit({});
  };

  const onsubmit = (dialogData) => {
    if (Object.keys(dialogData).length === 0) {
      onClose({});
    } else {
      const data = {
        data: dialogData,
      };
      onClose(data);
    }
  };

  return (
    <Suspense fallback="loading">
      <>
        <Typography align="center" variant="h4" component="h1" gutterBottom>
          {t("registerContent.customization.title")}
        </Typography>
        <p align="center">{t("registerContent.customization.subtitle")}</p>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <ReactHookFormRadio
              inputRef={register}
              error={errors.contentStyle && true}
              helperText={
                errors.contentStyle &&
                t("registerContent.customization.contentStyle.requiredErrorMsg")
              }
              id="contentStyle"
              name="contentStyle"
              label={t("registerContent.customization.contentStyle.label")}
              style={{ width: "100%" }}
              row={true}
              control={control}
              defaultValue="Marker"
            >
              <FormControlLabel
                value="GroundPlane"
                control={<Radio />}
                label="GroundPlane"
              />
              <FormControlLabel
                value="Marker"
                control={<Radio />}
                label="Marker"
              />
            </ReactHookFormRadio>

            <ReactHookFormRadio
              inputRef={register}
              error={errors.freemium && true}
              helperText={t(
                errors.freemium &&
                  "registerContent.customization.freemium.requiredErrorMsg"
              )}
              id="freemium"
              name="freemium"
              label={t("registerContent.customization.freemium.label")}
              style={{ width: "100%" }}
              row={true}
              control={control}
              defaultValue="0"
            >
              <FormControlLabel value="0" control={<Radio />} label="Não" />
              <FormControlLabel value="1" control={<Radio />} label="Sim" />
            </ReactHookFormRadio>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <div className={classes.root}>
              <input
                ref={register}
                accept="video/*"
                className={classes.input}
                id="gif"
                type="file"
                name="gif"
              />
              <label htmlFor="gif">
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  endIcon={<ImageIcon />}
                  component="span"
                >
                  {t("registerContent.customization.gif.textButton")}
                </Button>
                <span style={{ color: "red" }}>
                  {errors.gif && errors.gif.message}
                </span>
              </label>
            </div>

            <div className={classes.root}>
              <input
                ref={register}
                accept="image/*"
                className={classes.input}
                id="thumbnail"
                type="file"
                name="thumbnail"
              />
              <label htmlFor="thumbnail">
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  endIcon={<ImageIcon />}
                  component="span"
                >
                  {t("registerContent.customization.thumbnail.textButton")}
                </Button>
                <span style={{ color: "red" }}>
                  {errors.thumbnail && errors.thumbnail.message}
                </span>
              </label>
            </div>

            <div className={classes.root}>
              <input
                ref={register}
                accept="image/*"
                className={classes.input}
                id="marker"
                type="file"
                name="marker"
              />
              <label htmlFor="marker">
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  endIcon={<ImageIcon />}
                  component="span"
                >
                  {t("registerContent.customization.marker.textButton")}
                </Button>
                <span style={{ color: "red" }}>
                  {errors.marker && errors.marker.message}
                </span>
              </label>
            </div>

            <div className={classes.root}>
              <input
                ref={register}
                accept="video/*"
                className={classes.input}
                id="video"
                type="file"
                name="video"
              />
              <label htmlFor="video">
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  endIcon={<MovieIcon />}
                  component="span"
                >
                  {t("registerContent.customization.video.textButton")}
                </Button>
                <span style={{ color: "red" }}>
                  {errors.video && errors.video.message}
                </span>
              </label>
            </div>

            <div className={classes.root}>
              <input
                ref={register}
                accept="application/zip"
                className={classes.input}
                id="bundleAppStore"
                type="file"
                name="bundleAppStore"
              />
              <label htmlFor="bundleAppStore">
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  endIcon={<AttachFileIcon />}
                  component="span"
                >
                  {t("registerContent.customization.bundleAppStore.textButton")}
                </Button>
                <span style={{ color: "red" }}>
                  {errors.bundleAppStore && errors.bundleAppStore.message}
                </span>
              </label>
            </div>

            <div className={classes.root}>
              <input
                ref={register}
                accept="application/zip"
                className={classes.input}
                id="bundlePlayStore"
                type="file"
                name="bundlePlayStore"
              />
              <label htmlFor="bundlePlayStore">
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  endIcon={<AttachFileIcon />}
                  component="span"
                >
                  {t(
                    "registerContent.customization.bundlePlayStore.textButton"
                  )}
                </Button>
                <span style={{ color: "red" }}>
                  {errors.bundlePlayStore && errors.bundlePlayStore.message}
                </span>
              </label>
            </div>
          </Grid>

          <div className={classes.buttons}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => handleBack()}
            >
              {t("registerContent.backButton")}
            </Button>

            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleSubmit(onsubmit)}
            >
              {t("registerContent.nextButton")}
            </Button>
          </div>
        </Grid>
      </>
    </Suspense>
  );
}
