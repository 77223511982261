import React, { useState, createElement, forwardRef, useMemo } from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import MapAllowedRoutes from "../routes/MapAllowedRoutes";
import NavBarUserInfo from "./userInfo/header/navBarUserInfo";
import Box from "@material-ui/core/Box";
import Search from "../pages/InteractionsLibrary/components/search";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import CustomTooltip from "../components/customTooltip/CustomTooltip";
import { UserRole } from "../config/userRole";

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    paddingLeft: 0,
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 73,
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.background.default,
    boxShadow: "none",
  },
  appBarShift: {
    paddingLeft: 0,
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    display: "block",
  },
  hide: {
    display: "none",
  },
  hideVisibility: {
    visibility: "hidden",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    color: theme.palette.primary.main,
    marginLeft: "10px",
  },
  drawerOpen: {
    border: "none",
    background: theme.palette.background.default,
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    border: "none",
    background: theme.palette.background.default,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(8) + 4,
    },
  },
  drawerIcons: {
    minWidth: "0",
    padding: 12,
    color: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
  },
  selected: {
    minWidth: "0",
    color: theme.palette.primary.main,
    background: theme.palette.secondary.main,
    borderRadius: "10px",
    padding: 12,
  },
  toolbar: {
    padding: 8,
    alignItems: "center",
    justifyContent: "center",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  toolbarOpen: {
    backgroundColor: "red",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    marginTop: 64,
  },
  search: {
    marginLeft: 100,
  },
}));

export default function MainNavigation(props) {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const ListItemLink = (props) => {
    const { icon, primary, to, title } = props;

    const renderLink = useMemo(
      () =>
        forwardRef((itemProps, ref) => (
          <CustomTooltip
            title={title}
            arrow
            placement="right"
            TransitionComponent={Zoom}
          >
            <RouterLink to={to} ref={ref} {...itemProps} />
          </CustomTooltip>
        )),
      [title, to]
    );

    return (
      <li>
        <ListItem button component={renderLink} style={{ padding: "4px 8px" }}>
          <ListItemIcon
            className={
              to === window?.location.pathname
                ? classes.selected
                : classes.drawerIcons
            }
          >
            {createElement(icon)}
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={
              <Typography variant="body1" color="textPrimary">
                {primary}
              </Typography>
            }
            className={clsx(classes.drawer, {
              [classes.hideVisibility]: !open,
            })}
          />
        </ListItem>
      </li>
    );
  };

  const drawer = (
    <List>
      {props.routes &&
        props.routes.map(({ path, title, subheader, icon, display }) => (
          <div key={title}>
            {display && (
              <ListItemLink
                to={`${props.prefix}${path}`}
                icon={icon}
                primary={t(`menu.${title}`)}
                title={t(`menu.${title}`)}
              />
            )}
          </div>
        ))}
    </List>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar style={{ padding: 0 }}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            width="100%"
            flexWrap="nowrap"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              flexWrap="nowrap"
              justifyContent="flex-start"
            >
              <Box>
                <Hidden smUp>
                  <IconButton
                    color="primary"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    className={clsx(classes.menuButton, {
                      [classes.hide]: open,
                    })}
                  >
                    <MenuIcon />
                  </IconButton>
                </Hidden>
              </Box>
              <Box p={1}>
                <img
                  alt={classes.logo}
                  src="/images/regular/icone_xperience_normal.svg"
                />
              </Box>
              {userInfo &&
                userInfo.role[0] === UserRole.PROFESSOR &&
                window?.location.pathname.includes("/app/content") && (
                  <Hidden xsDown>
                    <Box p={1}>
                      <Search className={classes.search} />
                    </Box>
                  </Hidden>
                )}
            </Box>

            <Box>
              <NavBarUserInfo />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Hidden xsDown>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            {open ? (
              <>
                <IconButton onClick={handleDrawerClose}>
                  <CloseIcon color="primary" />
                </IconButton>
                <Hidden smUp>
                  <Search className={classes.search} />
                </Hidden>
              </>
            ) : (
              <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}
              >
                <MenuIcon />
              </IconButton>
            )}
          </div>

          {userInfo && drawer}
        </Drawer>
      </Hidden>

      <Hidden smUp>
        <Drawer
          variant="temporary"
          open={open}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
              <CloseIcon color="primary" />
            </IconButton>
            {userInfo &&
              userInfo.role[0] === UserRole.PROFESSOR &&
              window?.location.pathname.includes("/app/content") && (
                <Box p={1}>
                  <Search className={classes.search} />
                </Box>
              )}
          </div>

          {userInfo && drawer}
        </Drawer>
      </Hidden>

      <main className={classes.content}>
        <MapAllowedRoutes routes={props.routes} basePath="/app" isAddNotFound />
      </main>
    </div>
  );
}
