import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Image from "material-ui-image";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Chip from "@material-ui/core/Chip";
import { Hidden } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CreateIcon from "@material-ui/icons/Create";
import Grid from "@material-ui/core/Grid";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { cartDelete, cartUpdate } from "../../../store/duck/publish/cart";
import { excerpt } from "../../../utils/text";
import InteractionExtraDataDialog from "./InteractionExtraDataDialog";

const useStyles = makeStyles((theme) => ({
  itensList: {
    overflowX: "clip",
    overflowY: "scroll",
    maxHeight: 600,
    padding: 10,
  },
  listItem: {
    padding: theme.spacing(1, 1),
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
  deleteButtom: {
    color: theme.palette.error.light,
  },
  buttonSuccess: {
    color: "#47b04d",
    "&:hover": {
      color: "#388e3c",
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(4),
  },
  titleNumber: {
    color: theme.palette.primary.main,
  },
  configInteractionButton: {
    minWidth: "180px",
  },
  quimica: {
    borderColor: "#8a4077",
    color: "#8a4077",
  },
  biologia: {
    borderColor: "#579078",
    color: "#579078",
  },
  fisica: {
    borderColor: "#55b3c9",
    color: "#55b3c9",
  },
}));

const Review = (props) => {
  const classes = useStyles();
  const { onClose, formConfigI18n, setNoItemsInTheCart } = props;
  const {
    title,
    settingButton,
    interactionsToBePublished,
    backButton,
    publishButton,
  } = formConfigI18n.review;
  const [openPopUp, setOpenPopUp] = useState(false);
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const { items, loading } = cart;
  const [currentInteraction, setCurrentInteraction] = useState({});

  const removeInteraction = (interaction) => {
    dispatch(cartDelete(interaction));
  };

  const isInteractionCustomized = (interactionToFind) => {
    return items.find((interaction) =>
      interaction.idContent === interactionToFind
        ? interaction.hasOwnProperty("params")
        : false
    );
  };

  const handleOpen = async (interaction) => {
    setOpenPopUp(true);
    setCurrentInteraction(interaction);
  };

  const handleBack = () => {
    onsubmit({});
  };

  const getDialogData = async (data) => {
    dispatch(cartUpdate(currentInteraction, data));
  };

  const onsubmit = (dialogData) => {
    if (Object.keys(dialogData).length === 0) {
      onClose({});
    } else {
      const data = {
        next: {},
        data: items,
      };
      onClose(data);
    }
  };

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevItems = usePrevious(items);

  useEffect(() => {
    if (items?.length === 0 && !loading) {
      console.log("SEM ITEMS no carrinho");
      console.log(items);
      setNoItemsInTheCart(false);
    }
  }, [items, loading, openPopUp, prevItems, setNoItemsInTheCart]);
  return (
    <>
      <Grid item xs={12}>
        <Typography align="left" variant="h5" component="h2" gutterBottom>
          <span className={classes.titleNumber}>2. </span>
          {title}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <List className={classes.itensList}>
          {items &&
            items.map((interaction) => (
              <ListItem
                className={classes.listItem}
                key={interaction.idContent}
              >
                <Grid container spacing={1}>
                  <Grid item xs={3} sm={3} lg={2}>
                    <Image
                      cover={true}
                      aspectRatio={1 / 1}
                      style={{ borderRadius: "10px" }}
                      imageStyle={{ borderRadius: "10px" }}
                      alt={interaction.contentName}
                      src={interaction.st_thumbnail}
                    />
                    <Chip
                      style={{ marginTop: "10px" }}
                      classes={{
                        root: clsx({
                          [classes.quimica]: interaction.subject === "Química",
                          [classes.fisica]: interaction.subject === "Física",
                          [classes.biologia]:
                            interaction.subject === "Biologia",
                        }),
                      }}
                      variant="outlined"
                      size="small"
                      label={interaction.subject}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="h6" color="textPrimary" gutterBottom>
                      {interaction.contentName}
                    </Typography>
                    <Hidden xsDown>
                      <ListItemText
                        primary={excerpt(interaction.contentDescription, 150)}
                      />
                    </Hidden>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() => removeInteraction(interaction)}
                      className={classes.deleteButtom}
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12}>
                    {isInteractionCustomized(interaction.idContent) ? (
                      <Button
                        className={classes.configInteractionButton}
                        onClick={() => {
                          handleOpen(interaction);
                        }}
                        variant="contained"
                        color="primary"
                        startIcon={<CheckCircleOutlineIcon />}
                      >
                        {/* {settingButton} */}
                        Personalizado
                      </Button>
                    ) : (
                      <Button
                        className={classes.configInteractionButton}
                        onClick={() => {
                          handleOpen(interaction);
                        }}
                        variant="contained"
                        color="secondary"
                        startIcon={<CreateIcon color="primary" />}
                      >
                        {settingButton}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          <ListItem className={classes.listItem}>
            <ListItemText primary={interactionsToBePublished} />
            <Typography variant="subtitle1" className={classes.total}>
              {items && items.length > 0 ? items.length : "0"}
            </Typography>
          </ListItem>
        </List>
      </Grid>

      <Grid item xs={12}>
        <div className={classes.buttons}>
          <Button
            variant="text"
            color="primary"
            size="large"
            className={classes.button}
            onClick={() => handleBack()}
          >
            <ArrowBackIosIcon color="primary" /> {backButton}
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={() => onsubmit(cart)}
          >
            {publishButton}
          </Button>
        </div>
      </Grid>

      <InteractionExtraDataDialog
        onClose={getDialogData}
        openPopUp={openPopUp}
        setOpenPopUp={setOpenPopUp}
        interaction={currentInteraction}
      />
    </>
  );
};

export default Review;
