import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import Image from "material-ui-image";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import { Badge } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { cartAdd, cartDelete } from "../../../store/duck/publish/cart";
import { LibraryIcon } from "../../../icons/CustomIcons";
import { excerpt } from "../../../utils/text";

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 345,
  },
  title: {
    fontWeight: 700,
    lineHeight: 1.2,
    color: theme.palette.primary.contrastText,
  },
  description: {
    fontWeight: 400,
    height: 60,
    overflow: "hidden",
    lineHeight: 1.2,
    color: theme.palette.primary.contrastText,
  },
  quimica: {
    backgroundColor: theme.palette.quimica.backgroundColor,
  },
  biologia: {
    backgroundColor: theme.palette.biologia.backgroundColor,
  },
  fisica: {
    backgroundColor: theme.palette.fisica.backgroundColor,
  },
  quimicaText: {
    color: theme.palette.quimica.color,
  },
  biologiaText: {
    color: theme.palette.biologia.color,
  },
  fisicaText: {
    color: theme.palette.fisica.color,
  },
}));

const InteractionCard = (props) => {
  const { interaction, showInteractionDetails } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation("translation");
  const cart = useSelector((state) => state.cart);
  const { items } = cart;

  const configI18n = {
    fisica: t("InteractionsLibrary.filter.disciplines.fisica"),
    quimica: t("InteractionsLibrary.filter.disciplines.quimica"),
    biologia: t("InteractionsLibrary.filter.disciplines.biologia"),
  };

  const addToCart = (interaction) => {
    dispatch(cartAdd(interaction));
  };

  const removeItemCart = (interaction) => {
    dispatch(cartDelete(interaction));
  };

  const isItemExistIntheCart = (itemToFindId) => {
    return (
      items &&
      items.find((old_item) => old_item.idContent === itemToFindId.idContent)
    );
  };

  return (
    <Card
      classes={{
        root: clsx({
          [classes.quimica]: interaction.subject === "Química",
          [classes.fisica]: interaction.subject === "Física",
          [classes.biologia]: interaction.subject === "Biologia",
        }),
      }}
    >
      <CardActionArea
        onClick={() => showInteractionDetails(interaction.idContent)}
      >
        <Image
          cover={false}
          aspectRatio={16 / 9}
          alt={interaction.contentName}
          src={interaction.st_thumbnail}
        />
        <CardContent>
          <Typography
            variant="caption"
            component="p"
            classes={{
              root: clsx({
                [classes.quimicaText]: interaction.subject === "Química",
                [classes.fisicaText]: interaction.subject === "Física",
                [classes.biologiaText]: interaction.subject === "Biologia",
              }),
            }}
          >
            {interaction.subject === "Química" && configI18n.quimica}
            {interaction.subject === "Física" && configI18n.fisica}
            {interaction.subject === "Biologia" && configI18n.biologia}
          </Typography>
          <Typography
            className={classes.title}
            gutterBottom
            noWrap
            variant="h6"
            component="h2"
          >
            {excerpt(interaction.contentName, 50)}
          </Typography>

          <Typography
            variant="body2"
            className={classes.description}
            component="p"
          >
            {excerpt(interaction.contentDescription, 50)}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          onClick={
            !isItemExistIntheCart(interaction)
              ? () => addToCart(interaction)
              : () => removeItemCart(interaction)
          }
          disableElevation
          size="large"
          variant="contained"
          color="secondary"
          className={classes.add}
          fullWidth
        >
          {!isItemExistIntheCart(interaction)
            ? t("InteractionsLibrary.card.addButtom")
            : t("InteractionsLibrary.card.removeButton")}
          <IconButton>
            <Badge
              badgeContent={!isItemExistIntheCart(interaction) ? "+" : "-"}
              color={!isItemExistIntheCart(interaction) ? "primary" : "error"}
            >
              <LibraryIcon color="error" />
            </Badge>
          </IconButton>
        </Button>
      </CardActions>
    </Card>
  );
};

export default InteractionCard;
