import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import clsx from "clsx";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { Badge } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";
import { LibraryIcon } from "../../../icons/CustomIcons";
import { cartAdd, cartDelete } from "../../../store/duck/publish/cart";
import { listInteractionDetails } from "../../../store/interactions";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    flexGrow: 1,
  },
  title: {
    fontWeight: 700,
  },
  description: {
    backgroundColor: theme.palette.background.default,
  },
  quimica: {
    backgroundColor: theme.palette.quimica.backgroundColor,
  },
  biologia: {
    backgroundColor: theme.palette.biologia.backgroundColor,
  },
  fisica: {
    backgroundColor: theme.palette.fisica.backgroundColor,
  },
  quimicaText: {
    color: theme.palette.quimica.color,
  },
  biologiaText: {
    color: theme.palette.biologia.color,
  },
  fisicaText: {
    color: theme.palette.fisica.color,
  },
  dialogPaper: {
    overflowY: "unset",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
});

const CustomCloseButton = withStyles((theme) => ({
  root: {
    position: "absolute",
    zIndex: "1300 !important",
    right: -30 + theme.spacing(1),
    top: -30 + theme.spacing(1),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
}))(IconButton);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <CustomCloseButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </CustomCloseButton>
      ) : null}
    </>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const InteractionDetails = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation("translation");
  const cart = useSelector((state) => state.cart);
  const interactionDetails = useSelector((state) => state.interactionDetails);
  const { openPopUp, setOpenPopUp, interactionId } = props;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const isItemExistIntheCart = (itemToFindId) => {
    if (itemToFindId) {
      return cart.items.find(
        (old_item) => old_item.idContent === itemToFindId.idContent
      );
    }
  };

  const addToCart = (interaction) => {
    dispatch(cartAdd(interaction));
  };

  const removeItemCart = (interaction) => {
    dispatch(cartDelete(interaction));
  };

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevVisibility = usePrevious(openPopUp);

  useEffect(() => {
    if (prevVisibility !== openPopUp) {
      dispatch(listInteractionDetails(interactionId));
    }
  }, [dispatch, interactionId, openPopUp, prevVisibility]);

  console.log(interactionDetails);
  return (
    <Dialog
      PaperProps={{ className: classes.dialogPaper }}
      TransitionComponent={Transition}
      onClose={() => setOpenPopUp(false)}
      aria-labelledby="customized-dialog-title"
      open={openPopUp}
      style={{ minHeight: "300px" }}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={() => setOpenPopUp(false)}
      />

      {interactionDetails.loading ? (
        <>
          {isMobile ? (
            <Box display="flex" flexWrap="nowrap">
              <Box p={1}>
                <Skeleton
                  variant="rect"
                  width={300}
                  height={280}
                  style={{ marginBottom: "10px" }}
                />
                <Skeleton variant="rect" width={300} height={300} />
              </Box>
            </Box>
          ) : (
            <Box display="flex" flexWrap="nowrap">
              <Box p={1}>
                <Skeleton variant="rect" width={300} height={280} />
              </Box>

              <Box p={1}>
                <Skeleton variant="rect" width={300} height={300} />
              </Box>
            </Box>
          )}
        </>
      ) : (
        <DialogContent
          classes={{
            root: clsx({
              [classes.quimica]:
                interactionDetails.interaction.subject === "Química",
              [classes.fisica]:
                interactionDetails.interaction.subject === "Física",
              [classes.biologia]:
                interactionDetails.interaction.subject === "Biologia",
            }),
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <ReactPlayer
                width="100%"
                height="100%"
                url={interactionDetails.interaction.st_gif}
                playing
                loop={true}
                controls
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.description}>
              <Typography
                className={classes.title}
                variant="h4"
                component="h3"
                gutterBottom
                align="left"
              >
                {interactionDetails.interaction.contentName}
              </Typography>

              <Box
                style={{
                  height: "200px",
                  overflow: "scroll",
                  "overflow-x": "hidden",
                }}
              >
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textPrimary"
                  gutterBottom
                  align="left"
                >
                  {interactionDetails.interaction.contentDescription}
                </Typography>
              </Box>

              <Button
                onClick={
                  !isItemExistIntheCart(interactionDetails.interaction)
                    ? () => addToCart(interactionDetails.interaction)
                    : () => removeItemCart(interactionDetails.interaction)
                }
                disableElevation
                size="large"
                variant="contained"
                color="secondary"
                className={classes.add}
                fullWidth
              >
                {!isItemExistIntheCart(interactionDetails.interaction)
                  ? t("InteractionsLibrary.card.addButtom")
                  : t("InteractionsLibrary.card.removeButton")}
                <IconButton>
                  <Badge
                    badgeContent={
                      !isItemExistIntheCart(interactionDetails.interaction)
                        ? "+"
                        : "-"
                    }
                    color={
                      !isItemExistIntheCart(interactionDetails.interaction)
                        ? "primary"
                        : "error"
                    }
                  >
                    <LibraryIcon color="error" />
                  </Badge>
                </IconButton>
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default InteractionDetails;
