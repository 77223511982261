import ApiService from "../../../services/ApiService";

// Action types
const MY_INTERACTIONS_LIST_REQUEST = "MY_INTERACTIONS_LIST_REQUEST";
const MY_INTERACTIONS_LIST_SUCCESS = "MY_INTERACTIONS_LIST_SUCCESS";
const MY_INTERACTIONS_LIST_FAIL = "MY_INTERACTIONS_LIST_FAIL";

// Reducer
export const myContentListReducer = (state = { interactions: [] }, action) => {
  switch (action.type) {
    case MY_INTERACTIONS_LIST_REQUEST:
      return { loading: true, interactions: [] };
    case MY_INTERACTIONS_LIST_SUCCESS:
      return { loading: false, interactions: action.payload };
    case MY_INTERACTIONS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// Action Creators
export const listInteractions =
  (page = 1, limit = 3, keyword = "") =>
  async (dispatch) => {
    dispatch({ type: MY_INTERACTIONS_LIST_REQUEST });
    try {
      const { data } = await ApiService.get("/content/list?limit=1000");

      dispatch({
        type: MY_INTERACTIONS_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MY_INTERACTIONS_LIST_FAIL,
        payload: error,
      });
    }
  };
