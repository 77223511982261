import { showSnackbar } from "./duck/uiReducer/uiReducer";
import ApiService from "./../services/ApiService";

// Action types
const CONTENT_PUBLISH_REQUEST = "CONTENT_PUBLISH_REQUEST";
const CONTENT_PUBLISH_SUCCESS = "CONTENT_PUBLISH_SUCCESS";
const CONTENT_PUBLISH__FAIL = "CONTENT_PUBLISH__FAIL";

const CONTENT_UPDATE_REQUEST = "CONTENT_UPDATE_REQUEST";
const CONTENT_UPDATE_SUCCESS = "CONTENT_UPDATE_SUCCESS";
const CONTENT_UPDATE__FAIL = "CONTENT_UPDATE__FAIL";

const CONTENT_DELETE_REQUEST = "CONTENT_DELETE_REQUEST";
const CONTENT_DELETE_SUCCESS = "CONTENT_DELETE_SUCCESS";
const CONTENT_DELETE_FAIL = "CONTENT_DELETE_FAIL";

// Reducer
export const contentReducer = (state = { items: [] }, action) => {
  switch (action.type) {
    case CONTENT_PUBLISH_REQUEST:
      return { loading: true, items: [], status: action.payload };
    case CONTENT_PUBLISH_SUCCESS:
      return { loading: false, items: [], status: action.payload };
    case CONTENT_PUBLISH__FAIL:
      return {
        loading: false,
        items: action.payload.items,
        status: action.payload,
      };

    default:
      return state;
  }
};

export const contentUpdateReducer = (
  state = { isPublished: false, error: false },
  action
) => {
  switch (action.type) {
    case CONTENT_UPDATE_REQUEST:
      return { sending: true, isPublished: false, error: false };
    case CONTENT_UPDATE_SUCCESS:
      return { isPublished: true, error: false };
    case CONTENT_UPDATE__FAIL:
      return {
        isPublished: false,
        error: true,
      };

    default:
      return state;
  }
};

// Action Creators

export const contentPublish = (contentToPublish) => async (dispatch) => {
  try {
    dispatch({ type: CONTENT_PUBLISH_REQUEST });

    const {
      contentName,
      contentNameEn,
      contentNameFr,
      contentDescription,
      contentDescriptionEn,
      contentDescriptionFr,
      schoolSubjects,
      topic,
      effectiveDate,
      bncc,
      author,
      discipline,
      client,
      schoolLevel,
      schoolGrade,
      companyName,
      thumbnail,
      gif,
      marker,
      bundleAppStore,
      bundlePlayStore,
      contentStyle,
      contentType,
      freemium,
    } = contentToPublish;

    // TODO: Items para verificar
    /*
      customContent: 'true',
      nameMarker: 'theMarkerName',
      URL: 'a URL',
      effectiveDate: 'date',

    */
    let fullContent = {
      contentName: contentName || "",
      contentNameEn: contentNameEn || "",
      contentNameFr: contentNameFr || "",
      contentDescription: contentDescription || "",
      contentDescriptionEn: contentDescriptionEn || "",
      contentDescriptionFr: contentDescriptionFr || "",
      customContent: "true",
      nameMarker: "theMarkerName",
      URL: "a URL",
      effectiveDate: effectiveDate || "",
      schoolSubjects: schoolSubjects || "",
      theme: topic || "",
      themeEnem: schoolSubjects || "",
      BNCCSkills: bncc || "",
      owner: author || "",
      subject: discipline || "",
      nameSchool: client || "",
      schoolLevel: schoolLevel || "",
      serie: schoolGrade || "",
      client: companyName || "",
      companyName: companyName || "",
      contentStyle: contentStyle || "",
      contentType: "Basic",
      freemium: freemium || "",
    };

    const formData = new FormData();
    formData.append("fullContent", JSON.stringify(fullContent));
    formData.append("thumbnail", thumbnail[0]);
    formData.append("gif", gif[0]);
    formData.append("marker", marker[0]);
    formData.append("assetbundle_ios", bundleAppStore[0]);
    formData.append("assetbundle_and", bundlePlayStore[0]);

    // Display the key/value pairs
    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    const userInfo = localStorage.getItem("userInfo");
    const { jwt_token } = JSON.parse(userInfo);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${jwt_token}`,
      },
    };

    await ApiService.post(
      `${process.env.REACT_APP_BASE_API}/content/`,
      formData,
      config
    );
    dispatch({
      type: CONTENT_PUBLISH_SUCCESS,
      payload: { success: true },
    });
    showSnackbar({
      message: "Conteúdo publicada com sucesso!",
      severity: "success",
      autoHideDuration: 3000,
    });
  } catch (error) {
    dispatch({
      type: CONTENT_PUBLISH__FAIL,
      payload: { success: false, items: [], error },
    });
    showSnackbar({
      message: "Ocorreu um erro ao publicar o conteúdo!",
      severity: "error",
      autoHideDuration: 3000,
    });
  }
};

export const contentUpdate =
  (contentToPublish, interaction) => async (dispatch) => {
    try {
      dispatch({
        type: CONTENT_PUBLISH_REQUEST,
        payload: { isPublished: false, sending: true, error: false },
      });

      const {
        contentName,
        contentNameEn,
        contentNameFr,
        contentDescription,
        contentDescriptionEn,
        contentDescriptionFr,
        schoolSubjects,
        topic,
        effectiveDate,
        bncc,
        author,
        discipline,
        client,
        schoolLevel,
        schoolGrade,
        companyName,
        thumbnail,
        gif,
        marker,
        bundleAppStore,
        bundlePlayStore,
        contentStyle,
        contentType,
        freemium,
      } = contentToPublish;

      // TODO: Items para verificar
      /*
      customContent: 'true',
      nameMarker: 'theMarkerName',
      URL: 'a URL',
      effectiveDate: 'date',

    */
      let fullContent = {
        contentName: contentName || "",
        contentNameEn: contentNameEn || "",
        contentNameFr: contentNameFr || "",
        contentDescription: contentDescription || "",
        contentDescriptionEn: contentDescriptionEn || "",
        contentDescriptionFr: contentDescriptionFr || "",
        customContent: "true",
        nameMarker: "theMarkerName",
        URL: "a URL",
        effectiveDate: effectiveDate || "",
        schoolSubjects: schoolSubjects || "",
        theme: topic || "",
        themeEnem: schoolSubjects || "",
        BNCCSkills: bncc || "",
        owner: author || "",
        ownerId: interaction.ownerId || "",
        subject: discipline || "",
        nameSchool: client || "",
        schoolLevel: schoolLevel || "",
        serie: schoolGrade || "",
        client: companyName || "",
        companyName: companyName || "",
        contentStyle: contentStyle || "",
        contentType: "Basic",
        freemium: freemium || "",
      };

      const formData = new FormData();

      formData.append("fullContent", JSON.stringify(fullContent));

      if (bundleAppStore !== "") {
        formData.append("assetbundle_ios", bundleAppStore[0]);
      } else {
        fullContent["assetbundle_ios"] = "interaction.assetbundle_ios";
      }

      if (bundlePlayStore.length > 0) {
        formData.append("assetbundle_and", bundlePlayStore[0]);
      } else {
        fullContent["assetbundle_and"] = "interaction.assetbundle_and";
      }

      if (thumbnail !== "") {
        formData.append("thumbnail", thumbnail[0]);
      } else {
        fullContent["thumbnail"] = interaction.thumbnail;
      }

      if (gif !== "") {
        formData.append("gif", gif[0]);
      } else {
        fullContent["gif"] = interaction.gif;
      }

      if (marker !== "") {
        formData.append("marker", marker[0]);
      } else {
        fullContent["marker"] = interaction.marker;
      }

      // Display the key/value pairs
      for (var pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }

      const userInfo = localStorage.getItem("userInfo");
      const { jwt_token } = JSON.parse(userInfo);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${jwt_token}`,
        },
      };

      await ApiService.put(
        `${process.env.REACT_APP_BASE_API}/content/${interaction.idContent}`,
        formData,
        config
      );

      dispatch({
        type: CONTENT_UPDATE_SUCCESS,
        payload: { isPublished: true, error: false },
      });
      dispatch(
        showSnackbar({
          message: "Conteúdo atualizado com sucesso!",
          severity: "success",
          autoHideDuration: 3000,
        })
      );
    } catch (error) {
      dispatch({
        type: CONTENT_UPDATE__FAIL,
        payload: { isPublished: false, error: true },
      });
      dispatch(
        showSnackbar({
          message: "Ocorreu um erro ao atualizar o conteúdo!",
          severity: "error",
          autoHideDuration: 3000,
        })
      );
    }
  };

export const contentDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: CONTENT_DELETE_REQUEST });

    const { data } = await ApiService.delete(
      `${process.env.REACT_APP_BASE_API}/content/${id}`
    );
    dispatch({ type: CONTENT_DELETE_SUCCESS });
    return Promise.resolve(data);
  } catch (error) {
    dispatch({ type: CONTENT_DELETE_FAIL });
    return Promise.reject(error);
  }
};
